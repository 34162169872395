<template>
    <div class="mobile-mainpage-filters" v-if="translates && translates[langUrl]">
        <div class="mobile-mainpage-filters__contaiener">
            <!-- <div class="mobile-mainpage-filters__name">Направление</div> -->
            <!-- <v-text-field
                class="mobile-mainpage-filters__field"
                placeholder="Куда хотите поехать?"
                @change="updateFilterSearch"
            /> -->
            <img
                src="../../assets/MagnifyingGlassPink.svg"
                class="mobile-mainpage-filters__glass"
                @click="tripList" />
            <v-select
                class="mobile-mainpage-filters__field"
                :placeholder="translates[langUrl].filterTitle_1_chose[lang]"
                :value="filter.directions"
                :items="[{ code: null, title: translates[langUrl].allDirections[lang] }, ...countries]"
                item-text="title"
                item-value="code"
                @change="updateFilterDirections"
            >
                <template #append>
                    <img src="../../assets/DownArrow.svg" class="mobile-mainpage-filters__base-select__append" />
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

    export default {
        name: 'MobileMainpageFilter',
        components: {
        },
        data: () => ({
            filter: { 
                search: '',
                dates: [], 
                type: null,
                directions: '',
            },
            langUrl: 'MainpageFilters'
        }),
        computed: {
            ...mapState('dictionaries', ['countries', 'cities']),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            async tripList() {
                await this.$router.push({ name: 'trip-list', params: { filter: { directions: this.filter.directions, search: this.filter.search, dates: this.filter.dates, tripType: this.filter.tripType } } });
            },
            updateFilterSearch(value) {
                this.filter.search = value;
            },
            updateFilterDirections(value) {
                this.filter.directions = value;
                this.tripList()
            },
        }
    }
</script>

<style lang="scss">
.mobile-mainpage-filters {
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 37.5px;
    width: 335px;
    height: 56px;
    margin-top: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: Inter;
    font-style: normal;
    &__contaiener {
        width: 100%;
        padding: 13px 15px;
        text-align: left;
    }
    &__field {
        text-overflow: ellipsis;
            overflow: hidden;
        &.v-text-field {
            margin-top: 0;
            padding-top: 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &.v-text-field input {
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
            // color: #90949A;
            padding: 5px 55px 5px 70px;
        }
        .v-select__selection--comma {
            margin: 3px 55px 5px 70px;
            font-weight: 300;
            font-size: 18px;
            line-height: 150%;
        }
        &.theme--light.v-select .v-select__selections {
            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #90949A;
        }
    }
    // &__field {
    //     width: 100%;

    //     &.v-text-field {
    //         margin-top: 0;
    //         padding-top: 0;
    //         &:active {
    //             .mobile-mainpage-filters__glass {
    //                 display: none;
    //             }
    //         }
    //     }
    //     .v-text-field__details {
    //         display: none;
    //     }
    //     &.v-text-field input {
    //         font-weight: 600;
    //         font-size: 16px;
    //         line-height: 150%;
    //         letter-spacing: -0.02em;
    //         color: #273155;
    //         padding: 5px 55px 5px 73px;
    //     }
    // }
    &__base-select__append {
        margin-top: 10px;
        margin-left: -30px;
    }
    .v-input__slot::before {
        border: none !important;
    } 
    .v-input__slot::after {
        border: none !important;
    }
    &__glass {
        position: absolute;
        left: 50px;
        top: 20px;
        // z-index: 1;
    }
}
</style>