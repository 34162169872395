<template>
    <div v-if="pageTemplate && translates && translates[langUrl]" class="main-page full-width">
        <div
            v-if="pageTemplate.main && pageTemplate.main.show"
            class="main-page__main-image"
            :style="`background-image: url(${ imageSrc(pageTemplate.main.picture) })`">
            <div class="main-page__main-image__opacity"></div>
            <div class="main-page__main-image__container">
                <!-- <div v-if="!isDesktop" class="main-page__main-image__container__filter">
                    <v-text-field
                        label="Направление"
                    />
                </div> -->
                <MainpageFilter v-if="!isDesktop" />
                <MainpageFilters v-if="isDesktop" />
                <div class="main-page__main-image__container__text">{{ pageTemplate.main.title[lang] }}</div>
                <div class="main-page__main-image__container__comment">{{ pageTemplate.main.preview[lang] }}</div>
                <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="main-page__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title[lang] }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div
            v-if="pageTemplate.trips && pageTemplate.trips.show" 
            class="main-page__tours">
            <div class="main-page__block-title">{{ pageTemplate.trips.title[lang] }}</div>
            <div v-if="trips && trips.length <= 4 || !isDesktop" class="main-page__tours__container">
                <template v-for="(trip, index) in trips">
                    <PreviewCardHead
                        :key="index"
                        :trip="trip"
                        :buttons="['favorites']"
                        style="min-width: 263px"
                        @favoriteAdd="favoriteAdd"
                        @favoriteDelete="favoriteDelete"
                        @setStatus="setStatus" />
                </template>
            </div>
            <Splide
                v-if="trips && trips.length > 4 && isDesktop"
                style="margin-top: 30px;"
                :options="{ rewind: true, type: 'loop', perPage: tourSplideCounter, perMove: 1, pagination: false }">
                <template v-for="(trip, index) in trips">
                    <SplideSlide 
                        :key="index">
                        <PreviewCardHead
                            :trip="trip"
                            :buttons="['favorites']"
                            style="min-width: 335px; height: 98%"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete"
                            @setStatus="setStatus" />
                    </SplideSlide>
                </template>
            </Splide>
        </div>
        <div
            v-if="pageTemplate.banner && pageTemplate.banner.show"
            class="main-page__banner"
            :style="`background-image: url(${ imageSrc(pageTemplate.banner.picture) })`"
            @click="$router.push(pageTemplate.banner.link)"></div>
        <div 
            class="main-page__open-world"
            v-if="pageTemplate.offer && pageTemplate.offer.show">
            <div class="main-page__open-world__picture-container">
                <div class="main-page__block-title main-page__open-world__title">{{ pageTemplate.offer.title[lang] }}</div>
                <div
                    class="main-page__open-world__picture"
                    :style="`background-image: url(${ imageSrc(pageTemplate.offer.picture) })`">
                </div>
            </div>
            <div class="main-page__open-world__text-container">
                <!-- <div class="main-page__block-title main-page__open-world__title">{{ pageTemplate.offer.title[lang] }}</div> -->
                <div class="main-page__open-world__text" v-html="pageTemplate.offer.text[lang]"></div>
                <BaseButton @click="$router.push(pageTemplate.offer.link)" style="margin: 0 auto 0 0">{{ translates[langUrl].offerButton[lang] }}</BaseButton>
            </div>
        </div>
        <TripType
            v-if="pageTemplate.tripTypes && pageTemplate.tripTypes.show"
            :title="pageTemplate.tripTypes.title[lang]"
            :show-types="pageTemplate.tripTypes.types" />
        <NewsPreview 
            v-if="pageTemplate.news && pageTemplate.news.show && blocks.news.visible === true && blocks.news !== ['']" 
            :title="pageTemplate.news.title[lang]" 
            :need-news="isDesktop? newsCounter : 3"
        />
        <!-- <BaseButton @click="isVisible()" /> -->
        <div
            v-if="pageTemplate.organizers && pageTemplate.organizers.show" 
            class="main-page__organizers">
            <div class="main-page__block-title">{{ pageTemplate.organizers.title[lang] }}</div>
            <div class="main-page__organizers__container">
                <template v-for="(user, index) in users">
                    <Organizer
                        v-if="user.avatar && user.avatar !== ''"
                        :key="index"
                        :user="user"
                    />
                </template>
            </div>
            <BaseButton
                v-if="users.lenght > 6"
                style="margin-top: 30px;"
                class="main-page__button-width">{{ translates[langUrl].organizersButton[lang] }}</BaseButton>
        </div>
        
        <div
            v-if="pageTemplate.photos && pageTemplate.photos.show"
            class="main-page__gallery">
            <div class="main-page__block-title main-page__gallery-title">{{ pageTemplate.photos.title[lang] }}</div>
            <!-- <div class="main-page__gallery__photos"></div> -->
            <WaterfallGrid 
                :photos="photos"
                style="max-width: 1920px; max-height: 576px; margin: 20px auto 20px" />
            <a :href="pageTemplate.photos.link" target="_blank">
                <!-- <BaseButton class="button-fill main-page__button-width">
                    <img src="../assets/InstagramLogo.svg" style="width: 30px; heigth: 30px; margin-right: 5px" />
                    Подпишись в инстаграм
                </BaseButton> -->
            </a>
        </div>
        <div class="main-page__reviews" v-if="pageTemplate.reviews && pageTemplate.reviews.show">
            <div class="main-page__reviews__block-name">{{ pageTemplate.reviews.title[lang] }}</div>
            <div class="main-page__reviews__container">
                <template v-for="(review, index) in reviews.items">
                    <Review
                        :key="'review' + index"
                        :review="review"
                    />
                </template>
            </div>
            <BaseButton @click="goTo('reviews-list')">{{translates[langUrl].reviewsButton[lang] }}</BaseButton>
        </div>
        <div 
            v-if="pageTemplate.feedback && pageTemplate.feedback.show"
            class="main-page__bottom-image">
            <div class="main-page__bottom-image__text">
                <div class="main-page__bottom-image__name">{{ pageTemplate.feedback.title[lang] }}</div>
                <div class="main-page__bottom-image__description">{{ pageTemplate.feedback.preview[lang] }}</div>
            </div>
            <FormQuestion />
        </div>
    </div>
</template>

<script>
    import PreviewCardHead from '../components/common/trip/PreviewCardHead.vue';
    import TripType from '../components/common/TripType.vue';
    import NewsPreview from '../components/common/NewsPreview.vue';
    import Organizer from '../components/common/Organizer.vue';
    import Review from '../components/common/Review.vue';
    import FormQuestion from '../components/common/FormQuestion.vue';
    import BaseButton from '../components/common/BaseButton.vue';
    import MainpageFilters from '../components/desktop/Filters.vue';
    import MainpageFilter from '../components/mobile/Filter.vue';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import WaterfallGrid from '../components/common/WaterfallGrid.vue'

    import store from '@/store';
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';


    export default {
        name: 'Main',
        metaInfo: {
            title: 'Главная'
        },
        components: {
            PreviewCardHead,
            TripType,
            BaseButton,
            NewsPreview,
            Organizer,
            Review,
            FormQuestion,
            MainpageFilters,
            MainpageFilter,
            Splide,
            SplideSlide,
            WaterfallGrid
        },
        data: () => ({
            isDesktop: false,
            imageSrc,
            tourSplideCounter: 1,
            newsCounter: 1,
            filter: {
                
            },
            blocks: {
                tours: {
                    visible: true,
                    items: ['']
                },
                offer: {
                    visible: true,
                    pictire: ''
                },
                tripTypes: {
                    visible: true,
                    items: ['']
                },
                news: {
                    visible: true,
                    items: ['']
                },
                organizers: {
                    visible: true,
                },
                gallery: {
                    visible: true,
                    pictures: ['']
                }
            },
            langUrl: '/'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.main
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('users',{
                users: state => state.organizers
            }),
            ...mapState('news', {
                news: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('photos', {
                photos: state => state.entities.map(item => item)
            }),
            ...mapState('translates', {
                translates: state => state.entities
            })
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
            this.tourSplideCounter = SplideSlideCounter(350, 1400);
            this.newsCounter = SplideSlideCounter(460, 1400);
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            await this.getTrips();
            await this.getUsers();
            await store.dispatch('photos/fetch', {});
            await store.dispatch('reviews/fetch', { limit: 3, page: 1 });
        },
        methods: {
            async getUsers() {
                await store.dispatch('users/organizers');
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: { 
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting, page: 1, itemsPerPage: 10 });
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async favoriteAdd({ id }) {
                if (!this.user._id) {
                    await this.$router.push({ name: 'user-authorization' });
                } else {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                }
            },
            async favoriteDelete({ id }) {
                if (!this.user._id) {
                    await this.$router.push({ name: 'user-authorization' });
                } else {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            isVisible() {
                return this.blocks.news.visible = !this.blocks.news.visible
            },
            async goTo(name) {
                await this.$router.push({ name })
            },
        }
    }
</script>


<style lang="scss">
.main-page {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    &__button-width {
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    &__main-image {
        position: relative;
        width: 100%;
        height: 782px;
        background: url(../assets/MainImg.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 482px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #543919;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 782px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 482px;
            }
            &__filter {
                background: #FFFFFF;
                box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
                border-radius: 37.5px;
                width: 335px;
                height: 56px;
                margin-top: 35px;
            }
            &__text {
                font-weight: bold;
                font-size: 56px;
                margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    text-align: center;
                    margin-top: 115px;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
            &__directions {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                margin-top: 204px;
                cursor: pointer;
                &::-webkit-scrollbar {
                    display: none;
                }
                @media all and (max-width: 768px) {
                    display: none;
                    // margin-top: 150px;
                    // width: 375px;
                }
            }
            &__direction {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                padding: 10px 24px;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                border-radius: 22px;
                margin-right: 8px;
                white-space: nowrap;
                @media all and (max-width: 768px) {
                    &:first-of-type {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .splide__arrow {
        width: 40px;
        height: 40px;
        opacity: 1;
        padding: 12px;
        top: 45%;
        background: #fff;
        &:hover {
            background-color: #FFECF4;
        }
    }
    .splide__arrow--prev {
        content: url(../assets/BeforeArrow.svg);
        opacity: 1;
        left: 0;
    }
    .splide__arrow--next {
        content: url(../assets/NextArrow.svg);
        right: 0;
    }
    &__block-title {
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        @media all and (max-width: 768px) {
            margin-left: 20px;
            font-size: 24px;
        }
    }
    &__tours {
        margin: 70px 24px 0;
        @media all and (min-width: 1400px) {
            max-width: 1480px;
            padding: 0 40px;
            margin: 70px auto 0;
        }
        justify-content: flex-start;
        @media all and (max-width: 768px) {
            margin: 60px 0 0;
        }
        &__container {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        gap: 20px;
        overflow: visible;
        @media all and (max-width: 768px) {
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        }
    }
    &__banner {
        width: 100%;
        height: 21vw;
        min-height: 408px;
        margin-top: 50px;
        background: url(../assets/Offer.jpg);
        background-position: center;
        background-size: cover;
        cursor: pointer;
    }
    &__open-world {
        display: flex;
        margin: 70px 24px 0;
        @media all and (min-width: 1400px) {
            max-width: 1480px;
            padding: 0 40px;
            margin: 70px auto 0;
        }
        justify-content: space-between;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin: 50px 20px 0;
        }
        &__picture{
            min-height: 450px;
            min-width: 700px;
            border-radius: 12px;
            margin: auto 70px auto 0;
            // background-image: url(../assets/OpenWorld.png);
            background-position: center;
            background-size: cover;
            @media all and (max-width: 1200px) {
                min-height: 300px;
                min-width: 500px;
                margin: auto 50px auto 0;
            }
            @media all and (max-width: 1024px) {
                min-height: 200px;
                min-width: 300px;
                margin: auto 50px auto 0;
            }
            @media all and (max-width: 768px) {
                min-width: 100%;
                min-height: 300px;
                margin: 0 0 30px 0;
            }
        }
        // &__picture-container {

        // }
        &__text-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 700px;
            // text-align: left;
        }
        &__title {
            margin: 0 0 30px 0;
            @media all and (max-width: 768px) {
                margin: 0 0 30px 0;
            }
        }
        &__text {
            margin-bottom: 20px;
            text-align: left;
        }

    }
    &__organizers {
        margin: 80px 24px 0;
        @media all and (min-width: 1400px) {
            max-width: 1400px;
            margin: 80px auto 0;
        }
        &__container {
            display: flex;
            flex-direction: row;
            // gap: 16px;
            margin-top: 30px;
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        @media all and (max-width: 768px) {
            margin: 30px 0 0;
        }
    }
    &__gallery {
        margin: 80px 0;
        &-title {
            max-width: 1400px;
            margin: auto;
            @media all and(max-width: 768px) {
                margin-left: 20px;
            }
        }
        &__photos {
            background-image: url(../assets/Gallery.jpg);
            background-position: center;
            background-size: cover;
            margin: 15px 0; 
            width: 100%;
            height: 700px;
        }
    }
    &__reviews{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 1400px;
        margin: 0 auto 80px;
        &__block-name {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                padding-left: 20px;
            }
        }
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                margin: 30px 0 20px;
            }
        }
    }
    &__bottom-image{
        background: url(../assets/BottomPicture.jpg) no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 705px;
        // padding: 68px 380px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media all and (max-width: 1200px) {
            padding: 0 20px;
        }
        @media all and (max-width: 768px) {
            height: 838px;
            padding: 50px 20px;
            flex-direction: column;
            margin-right: 130px;
        }
        &__text {
            @media all and (max-width: 1200px) {
                margin-right: 40px;
            }
            @media all and (max-width: 768px) {
                margin-right: 0;
            }
        }
        &__name {
            font-weight: bold;
            font-size: 56px;
            line-height: 150%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                font-weight: 800;
                font-size: 24px;
            }
        }
        &__description {
            max-width: 588px;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                width: auto;
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
    }
    .splide__slide {
        align-items: center;
    }
}
</style>