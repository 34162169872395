<template>
    <div class="trip-types">
        <div class="trip-types__title">{{ title }}</div>
        <div v-if="filteredTypes.length <= tourTypesSplideCounter || !isDesktop" class="trip-types__container">
            <template v-for="type in filteredTypes">
                <div
                    :key="type.code"
                    class="trip-types__type mobile"
                    @click="updateFilterType(type.code)">
                    <div
                        class="trip-types__type__picture"
                        :style="type.picture ? `background-image: url(${ imageSrc(type.picture) })` : ''"></div>
                    <div class="trip-types__type__text">
                        <div class="trip-types__type__name"> {{ type.title }} </div>
                        <!-- <div class="trip-types__type__number">3 205 туров</div> -->
                    </div>
                </div>
            </template>
        </div>
        <Splide
            v-if="filteredTypes.length > tourTypesSplideCounter && isDesktop"
            style="margin-top: 30px;"
            :options="{ rewind: true, type: 'loop', perPage: tourTypesSplideCounter, perMove: tourTypesSplideCounter, pagination: false }">
            <template v-for="type in filteredTypes">
                <SplideSlide 
                    :key="type.code">
                    <div
                        class="trip-types__type"
                        @click="updateFilterType(type.code)">
                        <div
                            class="trip-types__type__picture"
                            :style="type.picture ? `background-image: url(${ imageSrc(type.picture) })` : ''"></div>
                        <div class="trip-types__type__text">
                            <div class="trip-types__type__name"> {{ type.title }} </div>
                        <!-- <div class="trip-types__type__number">3 205 туров</div> -->
                        </div>
                    </div>
                </splideslide></template>
        </Splide>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { isMobile } from '@/helpers';

    export default {
        name: 'TripType',
        components: {
            Splide,
            SplideSlide
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            showTypes: {
                type: Object,
                default: () => {},
            }
        },
        data: () => ({
            imageSrc,
            isDesktop: false,
            filter: { 
                type: null,
            },
            tourTypesSplideCounter: 1,
        }),
        computed: {
            ...mapState('dictionaries', ['types']),
            filteredTypes() {
                return this.types.filter(item => {
                    return this.showTypes[item.code] === true
                })
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.tourTypesSplideCounter = SplideSlideCounter(350, 1400);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            async tripList() {
                await this.$router.push({ name: 'trip-list', params: { filter: { tripType: this.filter.tripType } } });
            },
            updateFilterType(value) {
                this.filter.tripType = value;
                this.tripList()
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
        }
    }


</script>

<style lang="scss">
.trip-types {
    margin: 70px 24px 0;
        @media all and (min-width: 1400px) {
            max-width: 1400px;
            margin: 70px auto 0;
        }
    font-family: Inter;
    font-style: normal;
    @media all and (max-width: 768px) {
        margin: 30px 0 0;
    }
    &__title{
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        @media all and (max-width: 768px) {
            font-weight: 600;
            font-size: 24px;
            text-align: left;
            margin-left: 20px;
        }
    }
    &__container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 30px;
        padding-bottom: 30px;
        @media all and (max-width: 768px) {
            overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        }
    }
    &__type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
        border-radius: 12px;
        overflow: hidden;
        min-width: 335px;
        width: 335px;
        cursor: pointer;
        &.mobile {
            margin-right: 20px;
        }
        @media all and (max-width: 768px) {
            &:first-of-type {
                margin-left: 20px;
            }
        }
        @media all and (max-width: 768px) {
            min-width: 250px;
            width: 250px;
        }
        &__picture {
            width: 335px;
            height: 280px;
            background-size: cover;
        }
        &__text {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        // &__name {

        // }
        // &__number {

        // }
    }
    .splide__slide {
        align-items: center;
    }
    .splide__list {
        height: 350px;
    }
}
</style>